<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>{{ $t("exam.exams") }}</li>
              <li>{{ $t("exam.add") }}</li>
            </ul>
          </nav>
        </div>

        <template v-if="errorMessages">
          <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red">
              <i class="uil-exclamation-triangle"></i> {{ error }}
            </p>
          </div>
        </template>
        <div v-if="successMessage" class="uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"></i> {{ successMessage }}
          </p>
        </div>
        <div class="card mt-4 position-relative">
          <div v-if="loading" class="loading-bar">
            <div class="text-info" uk-spinner="ratio: 4"></div>
          </div>
          <div class="card-body">
            <h4><i class="uil-question-circle"></i> {{ $t("exam.add") }}</h4>
            <hr />
            <div class="row">
              <div class="col-xl-9 m-auto">
                <form action="">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="exam_title">{{
                      $t("exam.name")
                    }}<span class="required">*</span></label>
                    <div class="col-md-9">
                      <input id="exam_title" v-model="name" :placeholder="$t('exam.enter_name')" class="form-control"
                        required="" type="text" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="passmark">
                      {{ $t("exam.passmark") }} (0-100)<span class="required">*</span>
                    </label>
                    <div class="col-md-9">
                      <input id="passmark" v-model="passmark" :placeholder="$t('exam.enter_passmark')"
                        class="form-control" required="" type="text" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="duration">
                      {{ $t("exam.duration") }}<span class="required">*</span>
                    </label>
                    <div class="col-md-9">
                      <input id="duration" v-model="duration" :placeholder="$t('exam.enter_duration')"
                        class="form-control" required="" type="text" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_mix_questions") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="is_mix_questions"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_mix_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="is_mix_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_right_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="show_right_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_user_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="show_user_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_result") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="show_result"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_compulsory") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="is_compulsory"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>


                  <questions ref="myEvent" :questions="[]" action="create" modelType="Exam"></questions>

                  <!--                  <div class="form-group row mb-3">-->
                  <!--                    <label class="col-md-3 col-form-label" for="questions">-->
                  <!--                      {{ $t("exam.question_type") }} <span class="required">*</span>-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-9">-->
                  <!--                      <select-->
                  <!--                          class="selectpicker2"-->
                  <!--                          v-model="selectedQuestions"-->
                  <!--                          data-live-search="true"-->
                  <!--                          name="questions"-->
                  <!--                          id="questions"-->
                  <!--                          title="Seçim Yap"-->
                  <!--                          multiple-->
                  <!--                      >-->
                  <!--                        <option-->
                  <!--                            v-for="question in formatQuestionsItem"-->
                  <!--                            :value="question.id"-->
                  <!--                        >-->
                  <!--                          {{ question.text }}-->
                  <!--                        </option>-->
                  <!--                      </select>-->
                  <!--                      <draggable-->
                  <!--                          class="row flex-column"-->
                  <!--                          :list="selectedQuestions"-->
                  <!--                          group="widget"-->
                  <!--                      >-->
                  <!--                        <div v-for="(item, index) in selectedQuestions">-->
                  <!--                          <div class="col-12">-->
                  <!--                            <div class="mt-2 selected_questions shadow-sm">-->
                  <!--                              <div-->
                  <!--                                  class="-->
                  <!--                                  d-flex-->
                  <!--                                  justify-content-between-->
                  <!--                                  align-items-center-->
                  <!--                                "-->
                  <!--                              >-->
                  <!--                                {{ getQuestionName(item) }}-->
                  <!--                                <i-->
                  <!--                                    data-v-2cadc991=""-->
                  <!--                                    class="uil-align-justify"-->
                  <!--                                    style="color: rgb(212, 212, 212)"-->
                  <!--                                ></i>-->
                  <!--                              </div>-->
                  <!--                            </div>-->
                  <!--                          </div>-->
                  <!--                        </div>-->
                  <!--                      </draggable>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <div class="d-flex justify-content-end  align-items-center">
                    <toggle-button v-model="is_default"
                      :labels="{ checked: $t('exam.default'), unchecked: $t('exam.not_default') }" :sync="true"
                      :width="120" color="#ae71ff" />
                  </div>

                  <div class="uk-card-footer float-right pb-0 pr-0">
                    <button class="uk-button uk-button-primary small" type="button" @click="saveForm">
                      <i class="icon-feather-arrow-right"></i>{{ $t("general.save") }}
                    </button>
                    <button class="uk-button uk-button-primary small" style="margin-left:5vh" type="button"
                      @click="saveForm('assign_page')">
                      <i class="uil-location-arrow"></i> {{ $t("Kaydet ve Atama yap") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import store from "@/core/services";
// import questions_module, {
//   BASE_URL as QUESTIONS_BASE_URL,
//   MODULE_NAME as QUESTIONS_MODULE_NAME,
//   GET_ITEMS as QUESTIONS_GET_ITEMS,
//   ITEMS as QUESTIONS_ITEMS,
// } from "@/core/services/store/question.module";
import Questions from "@/view/components/question/Questions";

import module, { BASE_URL, CREATE_ITEM, ERROR, LOADING, MODULE_NAME, SUCCESS } from "@/core/services/store/exam.module";

//const _QUESTIONS_MODULE_NAME = QUESTIONS_MODULE_NAME;
const _MODULE_NAME = MODULE_NAME;

export default {
  name: "examAdd",
  components: { draggable, Questions },
  data() {
    return {
      errorMessages: [],
      successMessage: null,
      //selectedQuestions: [],
      name: null,
      passmark: 50, //max: 100
      is_mix_questions: true,
      is_mix_answers: true,
      show_right_answers: true,
      show_user_answers: true,
      show_result: true,
      is_compulsory: true,
      duration: 20,
      is_default: false,
      questions: [],
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    // registerStoreModule(_QUESTIONS_MODULE_NAME, questions_module);
    registerStoreModule(_MODULE_NAME, module);
  },
  computed: {
    loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    // questions: {
    //   get() {
    //     return store.getters[QUESTIONS_MODULE_NAME + "/" + QUESTIONS_ITEMS];
    //   },
    //   set(value) {
    //   },
    // },
    error: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    success: {
      get() {
        return store.getters[_MODULE_NAME + "/" + SUCCESS];
      },
      set(value) {
      },
    },
    // formatQuestionsItem() {
    //   let results = [];
    //   if (this.questions != null && this.questions.length ) {
    //     this.questions.forEach(currentItem => {
    //       results.push({
    //         id: currentItem.id,
    //         text: currentItem.text,
    //         order: currentItem.id,
    //       })
    //     });
    //     return results;
    //   }
    // }
  },
  methods: {
    saveForm(process_type) {
      let self = this;
      let validated = true;

      let formData = new FormData();
      let myQuestions = self.$refs.myEvent.handleNewQuestions();


      if (self.name == null || self.name.length < 1) {
        self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("exam.name")));
        self.scrollToTop();
        validated = false;
      }

      if (myQuestions.length === 0) {
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.question")));
        self.scrollToTop();
        validated = false;
      }

      if (self.duration == null || +self.duration < 1) {
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.duration")));
        self.scrollToTop();
        validated = false;
      }

      //formData.append('questions', JSON.stringify(myQuestions));


      myQuestions.forEach((question, questionIndex) => {
        let isCorrectCount = 0;
        let hasNullName = false;
        let hasNullMedia = false;

        if (question.text === '') {
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.text")));
          self.scrollToTop();
          return false;
        }

        question.options.forEach(option => {
          if (option.name === '') {
            hasNullName = true;
          }

          if (option.is_correct) {
            isCorrectCount++
          }

          if (typeof option.media === "undefined") {
            hasNullMedia = true;
          }
        });

        if (hasNullName && (question.type !== 4 && question.type !== 5)) {
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.option_name")));
          self.scrollToTop();
          return false;
        }

        if (isCorrectCount === 0) {
          self.errorMessages.push(self.$t("question.required.true_count"));
          self.scrollToTop();
          return false;
        }

        if (+question.type === 1 && isCorrectCount > 1) {
          self.errorMessages.push(self.$t('question.required.true_count'));
          self.scrollToTop()
          return false;
        }

        if (hasNullMedia && (question.type === 4 || question.type === 5)) {
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.option_media")));
          self.scrollToTop();
          return false;
        }


        formData.append('questions[' + questionIndex + '][type]', question.type);
        formData.append('questions[' + questionIndex + '][text]', question.text);
        formData.append('questions[' + questionIndex + '][media]', question.media);
        //formData.append('questions['+questionIndex+'][options]', JSON.stringify(question.options));

        question.options.forEach((option, optionIndex) => {
          formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][name]', option.name);
          formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][is_correct]', option.is_correct);
          if (question.type === 4 || question.type === 5) {
            formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][media]', option.media);
          }
        });
      });


      //formData.append("question_selection", self.selectedQuestions);
      formData.append("name", self.name);
      formData.append("passmark", self.passmark);
      formData.append("duration", self.duration);
      formData.append("is_default", self.is_default ? 1 : 0);
      formData.append("is_mix_questions", self.is_mix_questions ? 1 : 0);
      formData.append("is_mix_answers", self.is_mix_answers ? 1 : 0);
      formData.append("show_right_answers", self.show_right_answers ? 1 : 0);
      formData.append("show_user_answers", self.show_user_answers ? 1 : 0);
      formData.append("show_result", self.show_result ? 1 : 0);
      formData.append("is_compulsory", self.is_compulsory ? 1 : 0);

      if (validated) {
        store
          .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
            url: BASE_URL,
            contents: formData,
            // {
            //   name: self.name,
            //   passmark: self.passmark,
            //   is_default: self.is_default,
            //   //question_selection: self.selectedQuestions
            // },
          }).then((response) => {
            if (process_type === 'assign_page') {
              this.$router.push({
                name: "company.assignments.add",
                params: { data: { id: response.content_id, title: response.name, name: 'Sınav' } }
              });
            } else {
              this.successMessage = this.$t("general.successfully_created");
              this.$router.push({ name: "company.exams.list" });
            }
          });
      }

    },
    // getQuestionName(id) {
    //   for (let i = 0; i < this.questions.length; i++) {
    //     if (this.questions[i].id == id) {
    //       return this.questions[i].text;
    //     }
    //   }
    // },
    // getQuestions() {
    //   this.$store.dispatch(QUESTIONS_MODULE_NAME + "/" + QUESTIONS_GET_ITEMS, {
    //     url: QUESTIONS_BASE_URL,
    //     filters: {
    //       for_select_options: true,
    //     },
    //   });
    // },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    //this.getQuestions();
  },
  watch: {
    // success: function (val) {
    //   if (val) {
    //     this.successMessage = this.$t("general.successfully_created");
    //     this.$router.push({ name: "company.exams.list" });
    //   }
    // },
    errors: function (val) {
      if (val) {
        this.scrollToTop();
        this.errorMessages.push(val);
      }
    },
    // formatQuestionsItem() {
    //   setTimeout(() => {
    //     $(".selectpicker2").selectpicker();
    //   }, 600);
    // },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 5000);
  },
};
</script>
<style scoped>
.selected_questions {
  color: #ae71ff;
  border: 1px solid #eaeaea;
  display: block;
  font-size: 13px;
  line-height: 33px;
  padding: 1px 7px;
  border-radius: 8px;
}

.loading-bar {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgba(238, 238, 238, 0.5);
  display: flex;
  justify-content: center;
  align-items: center
}
</style>
